$(document).ready(function() {

    if($("#pdf-download-modal").length > 0) {

        // Get the modal
        var modal = document.getElementById("pdf-download-modal");

        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("pdf-download-close")[0];

        // When the user clicks on <span> (x), close the modal
        span.onclick = function () {
            modal.style.display = "none";
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }
    }

    if($("#newsletter-modal").length > 0) {

        // Get the modal
        var newsLetterModal = document.getElementById("newsletter-modal");

        // Get the <span> element that closes the modal
        var newsLetterSpan = document.getElementsByClassName("newsletter-close")[0];

        // When the user clicks on <span> (x), close the modal
        newsLetterSpan.onclick = function () {
            newsLetterModal.style.display = "none";
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target == newsLetterModal) {
                newsLetterModal.style.display = "none";
            }
        }

    }

});
