$(document).ready(function() {


    ////////////////////////////
    // styling change for IE11
    if(!!window.MSInputMethodContext && !!document.documentMode){
        let searchBarMarginLeft = 80;
        let searchBarWidth = 574;

        if(window.innerWidth < 1400){
            searchBarMarginLeft = 0;
            searchBarWidth = 444;

            $("#closeButton i").css("marginLeft", "0");
        }

        if(window.innerWidth < 1200 && window.innerWidth > 1022){
            searchBarWidth = 400;

            $(".header-logo").css("width", "228px");
        }

        $("#search-bar").css({
            marginLeft: searchBarMarginLeft + "px",
            width: + searchBarWidth + "px"
        });
    }

    /////////////////////////////
    // styling change for Safari
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        $(".individual-submenu-dropdown").css("top", "16px");
    }


    /////////////////////////
    // open/close search bar
    (function () {
        let searchBarWidth = 0;
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        function setSearchBarWidth(){
            const windowScreen = document.body.clientWidth;

            if(windowScreen > 1384){
                searchBarWidth = 600;
            }
            if(windowScreen > 1185 && windowScreen < 1384){
                searchBarWidth = 540;
            }
            if(windowScreen < 1185){
                if(isIE11) {
                    searchBarWidth = 410;
                }else{
                    searchBarWidth = 430;
                }
            }
        }

        window.onresize = function(){
            setSearchBarWidth();

            if($("#closeButton").css('display') === 'inline-block'){
                $("#search-bar").css('width', searchBarWidth + 'px');
            }
        };

        setSearchBarWidth();

        // open
        $(".search-menu-item").click(function () {
            $(".blue-menu-item").css('display', 'none');
            $(".search-menu-item").css('display', 'none');
            $("#search-bar").css("display", "block").animate({
               width: searchBarWidth + "px"
            }, 400, function() {
               $("#search-bar").css("display", "inline-block");
               $("#closeButton").css("display", "inline-block");
               $("#searchField").css("display", "inline-block");
               $("#searchField").focus();
            });
        });

        // close
        $("#closeButton").click(function () {
            var searchFieldText = $('#searchField').val();
            if (searchFieldText) {
                $('#searchField').val("");
                $("#searchField").focus();
                return;
            }

            $("#closeButton").css("display", "none");
            $("#searchField").css("display", "none");
            $( "#search-bar" ).animate({
                width: 0
            }, 300, function () {
                $("#search-bar").css("display", "none");
                $("#site-search-autocomplete").css("display","none");
                $("#site-search-autocomplete-exec").css("display","none");
                $(".blue-menu-item").css('display', 'inline-block');
                $(".search-menu-item").css('display', 'inline-block');
            });
        });

    })();

    ///////////////////////////////////////////
    // change the height of the header menu bar
    // on scroll (is it on top or not)
    $(window).scroll(function () {
        if($("#header").length > 0) {
            if ($("#header").offset().top > 0) {
                $("#header").addClass("smallHeader");
            } else if ($("#header").offset().top === 0) {
                $("#header").removeClass("smallHeader");
            }
        }
    });


    ////////////////////////////////
    // open/close mobile menu
    // open/close language bar
    ///////////////////////////////
    let isMobileMenuOpen = false;
    let isMobileLangBarOpen = false;
    let isMobileSubMenuOpen = false;
    let isSearchBarOpen = false;
    let initialPositionTop = $("#mobileMenu").position().top;

    function closeMobileMenu(){
        $("#mobileMenu").animate({right: '-100%'}, 300);
        $("#mobileBurger svg").css("fill", "#31417A");
        $("body").css("overflow", "scroll");
        isMobileMenuOpen = false;
        $("#mobileMenu").css("top", initialPositionTop);
        if(isMobileSubMenuOpen){
            $(".mobileMenu_catArrow").click();
        }
    }

    function closeMobileLangBar(){
        $("#mobileLangBar").animate({right: '-100%'}, 300);
        $("#mobileLang svg").css("fill", "#31417A");
        $("body").css("overflow", "scroll");
        isMobileLangBarOpen = false;
    }

    function closeMenuSubCategory(subCatContainer, clickedArrow){
        subCatContainer.css("height", 0);
        clickedArrow.removeClass("rotateArrow");
        isMobileSubMenuOpen = false;
    }
    // get the browser window width on loading the document
    // correction is used to adjust the amount of top added depending on the resolution
    const browserScreen = document.body.clientWidth;
    let correction = 0;
    if(browserScreen >= 766){
        correction = 17;
    }
    if(browserScreen < 766 && browserScreen >= 478){
        correction = 0;
    }
    if(browserScreen < 478 && browserScreen >= 350) {
        correction = 0;
    }
    if(browserScreen < 350 ) {
        correction = 0;
    }

    // get the inpage navigation/s
    let inpageSeminar = $("#pageInternalMenuSeminar");
    let inpageNav = $("#pageInternalMenu");

    function adjustMobileMenu(inpageEl, inpageStickySubmenuClassName, mobileHeaderIdName) {
        if(inpageEl.hasClass(inpageStickySubmenuClassName)) {
            let inpagePos = inpageEl.outerHeight();
            let headerHeight = $("#"+mobileHeaderIdName).outerHeight();
            console.log(headerHeight,"headerheight");
            console.log(inpagePos,"inpageheight");
            let topToAdd = headerHeight + inpagePos - correction;
            console.log(topToAdd);
            $("#mobileMenu").css("top", topToAdd);
        }
    }

    $("#mobileBurger").click(function() {
        if(isMobileMenuOpen){
            // close the menu
            closeMobileMenu();
        }else{
            closeMobileLangBar();
            // checks if the inpage nav is stuck to the nav
            // adjusts the top property of the mobile menu and corrects it depending on the resolution
            console.log($("#mobileHeader"),"mobileburger click");
            if($("#mobileHeader")) {
                adjustMobileMenu(inpageNav,"stickySubmenu", "mobileHeader");
            } else {
                adjustMobileMenu(inpageSeminar,"stickySubmenu-OS", "mobileHeader-OS");
            }

            // open the menu
            $("#mobileMenu").animate({right: 0}, 300);
            $("#mobileBurger svg").css("fill", "#79A342");
            $("body").css("overflow", "hidden");
            isMobileMenuOpen = true;
        }
    });

    $("#mobileLang").click(function() {
        if(isMobileLangBarOpen){
            // close the menu
            closeMobileLangBar();
        }else{
            closeMobileMenu();

            // open the menu
            $("#mobileLangBar").animate({right: 0}, 300);
            $("#mobileLang svg").css("fill", "#79A342");
            $("body").css("overflow", "hidden");

            isMobileLangBarOpen = true;
        }
    });

    // click on main menu element in mobile menu
    // open sub category elements
    $(".mobileMenu_catArrow").click(function() {
        const clickedArrow = $(this);
        const parentCatContainer = clickedArrow.parent();
        const subCatContainer = parentCatContainer.find(".mobileMenu_subCatContainer");

        if(isMobileSubMenuOpen){
            closeMenuSubCategory(subCatContainer, clickedArrow);
        }else{
            subCatContainer.css("height", "auto");
            clickedArrow.addClass("rotateArrow");
            isMobileSubMenuOpen = true;
        }
    });


    //////////////////////////////////////
    // mobile header search bar
    let naimationWidth;
    var windowsize = $(window).outerWidth();
    console.log(windowsize);
    $("#mobileSearch .-search").click(function() {
        naimationWidth = "540px";

        if(windowsize < 766 && windowsize > 478){
            naimationWidth = "278px";
        }
        if(windowsize < 478 && windowsize > 350){
            naimationWidth = "190px";
        }
        if(windowsize < 350){
            naimationWidth = "170px";
        }

        if(isSearchBarOpen){
            //send the search word

        }else{
            // open search bar
            $("#mobileSearch").css("backgroundColor", "#f4f5f8").animate({
                width: naimationWidth
            }, 300, function() {
                $("#mobileSearch .-times").css("display", "inline-block");
                $("#mobileSearch input").css("display", "inline-block").focus();
                $("#mobileHeader_logo").css("display", "none");
            });
        }
    });

    // close search bar
    $("#mobileSearch .-times").click(function() {
        naimationWidth = "62px";
        $("#mobileSearch .-times").css("display", "none");
        $("#site-search-autocomplete").css("display", "none");
        $("#site-search-autocomplete-exec").css("display","none");
        $("#mobileSearch input").css("display", "none");
        $("#mobileHeader_logo").css("display", "inline-block");
        $("#mobileSearch").animate({
            width: naimationWidth
        }, 300, function() {
            $("#mobileSearch").css("backgroundColor", "transparent")
        })
    });


});
