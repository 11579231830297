$(document).ready(function() {
    function unique(list) {
        var result = [];
        $.each(list, function(i, e) {
            if ($.inArray(e, result) == -1) result.push(e);
        });
        return result;
    }

    var isAuthorInstance = window.location.pathname.includes("author") ? "/author" : "";
    var url = window.location.origin + isAuthorInstance + "/.rest/solrSearch/searchCall";
    var templatePath = $("#searchLinkPath").attr('searchLink');
    var execEdTemplatePath = "individuals/solr-search-execed-results.html";
    var mainTemplatehPath = "Solr-Search-Result.html";
    var searchPath = window.location.pathname.includes("individuals") ? execEdTemplatePath : mainTemplatehPath;

    $("#searchField").on("input", function() {
        var dInput = this.value;
        dInput = dInput.toLowerCase().replace(/</g, "&lt;").replace(/>/g, "&gt;");
        $.ajax({
            type: "GET",
            url: url,
            data: {
                queryString: dInput
            },
            success: function (data) {
                $("#site-search-autocomplete").show();
                if (data !== "") {
                    data = JSON.parse(data);
                    var searchWord = dInput;
                    var suggestParent = data.suggest.AnalyzingInfixSuggester[searchWord];
                    var suggestions = suggestParent["suggestions"];
                    $("#site-search-autocomplete").empty();
                    var results = [];
                    var regexUrl = new RegExp("https:\\/\\/www.frankfurt-school.de\\/(es|fr|zh|ru|tr)\\/.*");
                    for (var sug in suggestions) {
                        var item = suggestions[sug];
                        if(!regexUrl.test(item["payload"])) {
                            results.push(item["term"]);
                        }
                    }
                    results = results.filter(
                        function (a) {
                            if (!this[a]) {
                                this[a] = 1;
                                return a;
                            }
                        },
                        {}
                    );

                    for (const term in results) {
                        var resUrl = results[term].replace(/<[^>]*>?/gm, '').replace(/</g, "&lt;").replace(/>/g, "&gt;");
                        $("#site-search-autocomplete").append("<div class='site-search-autocomplete-term'><a href='/home/" + searchPath + "?queryStr=" + resUrl + "&queryAutocomplete=true" + "'>" + results[term] + "</a></div>");
                    }
                }
            },
            error: function (xhr, status, error) {
                console.log(error.responseText);
            }
        });
    });

    $("#searchField-OS").on("input", function() {
        var dInput = this.value;
        dInput = dInput.toLowerCase();
        $.ajax({
            type: "GET",
            url: url,
            data: {
                queryString: dInput
            },
            success: function (data) {
                $("#site-search-autocomplete-exec").show();
                if (data !== "") {
                    data = JSON.parse(data);
                    var searchWord = dInput;
                    var suggestParent = data.suggest.AnalyzingInfixSuggester[searchWord];
                    var suggestions = suggestParent["suggestions"];
                    $("#site-search-autocomplete-exec").empty();
                    var results = [];
                    var regexUrl = new RegExp("https:\\/\\/www.frankfurt-school.de\\/(en|es|fr|zh|ru|tr)\\/.*");
                    for (var sug in suggestions) {
                        var item = suggestions[sug];
                        if(!regexUrl.test(item["payload"])) {
                            results.push(item["term"]);
                        }
                    }
                    results = results.filter(
                        function (a) {
                            if (!this[a]) {
                                this[a] = 1;
                                return a;
                            }
                        },
                        {}
                    );

                    // for (const term in results) {
                    //     var resUrl = results[term].replace(/<[^>]*>?/gm, '').replace(/</g, "&lt;").replace(/>/g, "&gt;");
                    //     $("#site-search-autocomplete-exec").append("<div class='site-search-autocomplete-term'><a href='/home/" + searchPath + "?queryStr=" + resUrl + "'>" + results[term] + "</a></div>");
                    // }
                }
            },
            error: function (xhr, status, error) {
                console.log(error.responseText);
            }
        });
    });

    // mobileSearchField

    $("#mobileSearchField").on("input", function() {
        var dInput = this.value;
        dInput = dInput.toLowerCase().replace(/</g, "&lt;").replace(/>/g, "&gt;");
        this.target.value = dInput;
        $.ajax({
            type: "GET",
            url: url,
            data: {
                queryString: dInput
            },
            success: function (data) {
                $("#site-search-autocomplete-mobile").show();
                if (data !== "") {
                    data = JSON.parse(data);
                    var searchWord = dInput;
                    var suggestParent = data.suggest.AnalyzingInfixSuggester[searchWord];
                    var suggestions = suggestParent["suggestions"];
                    $("#site-search-autocomplete-mobile").empty();
                    var results = [];
                    var regexUrl = new RegExp("https:\\/\\/www.frankfurt-school.de\\/(en|es|fr|zh|ru|tr)\\/.*");
                    for (var sug in suggestions) {
                        var item = suggestions[sug];
                        if(!regexUrl.test(item["payload"])) {
                            results.push(item["term"]);
                        }
                    }
                    results = results.filter(
                        function (a) {
                            if (!this[a]) {
                                this[a] = 1;
                                return a;
                            }
                        },
                        {}
                    );

                    // for (const term in results) {
                    //     var resUrl = results[term].replace(/<[^>]*>?/gm, '').replace(/</g, "&lt;").replace(/>/g, "&gt;");
                    //     $("#site-search-autocomplete-mobile").append("<div class='site-search-autocomplete-term'><a href='/home/" + searchPath + "?queryStr=" + resUrl + "'>" + results[term] + "</a></div>");
                    // }
                }
            },
            error: function (xhr, status, error) {
                console.log(error.responseText);
            }
        });
    });

    // for OS site
    $("#mobileSearchField-OS").on("input", function() {
        var dInput = this.value;
        dInput = dInput.toLowerCase();
        $.ajax({
            type: "GET",
            url: url,
            data: {
                queryString: dInput
            },
            success: function (data) {
                $("#site-search-autocomplete-mobile").show();
                if (data !== "") {
                    data = JSON.parse(data);
                    var searchWord = dInput;
                    var suggestParent = data.suggest.AnalyzingInfixSuggester[searchWord];
                    var suggestions = suggestParent["suggestions"];
                    $("#site-search-autocomplete-mobile").empty();
                    var results = [];
                    var regexUrl = new RegExp("https:\\/\\/www.frankfurt-school.de\\/(en|es|fr|zh|ru|tr)\\/.*");
                    for (var sug in suggestions) {
                        var item = suggestions[sug];
                        if(!regexUrl.test(item["payload"])) {
                            results.push(item["term"]);
                        }
                    }
                    results = results.filter(
                        function (a) {
                            if (!this[a]) {
                                this[a] = 1;
                                return a;
                            }
                        },
                        {}
                    );

                    // for (const term in results) {
                    //     var resUrl = results[term].replace(/<[^>]*>?/gm, '').replace(/</g, "&lt;").replace(/>/g, "&gt;");
                    //     $("#site-search-autocomplete-mobile").append("<div class='site-search-autocomplete-term'><a href='/home/" + searchPath + "?queryStr=" + resUrl + "'>" + results[term] + "</a></div>");
                    // }
                }
            },
            error: function (xhr, status, error) {
                console.log(error.responseText);
            }
        });
    });

});

$(document).click(function (e) {
    // hide dropdown autocomplete on click
    if (!$("#site-search-autocomplete").is(e.target) && $("#site-search-autocomplete").has(e.target).length == 0) {
        $("#site-search-autocomplete").hide();
    }

    if (!$("#site-search-autocomplete-exec").is(e.target) && $("#site-search-autocomplete-exec").has(e.target).length == 0) {
        $("#site-search-autocomplete-exec").hide();
    }


    if (!$("#site-search-autocomplete-mobile").is(e.target) && $("#site-search-autocomplete-mobile").has(e.target).length == 0) {
        $("#site-search-autocomplete-mobile").hide();
    }
});