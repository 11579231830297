$(document).ready(function() {

        // Main Site Cookies

        //www.frankfurt-school.de Domain
        document.cookie = 'uslk_e=NDQ4ZDUwNWMtNGRkOS1hOTViLTY3ODAtYjFkYmQ1MzllNGY2~~~~~~~~; Domain=www.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = 'uslk_e=YzZkYzZiNTItMzY2MC03NTVlLTQxZjctYzAzZGJiODc1MjM3~~~~~~~~; Domain=www.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = 'uslk_umm_32867_c={%22version%22:%221%22%2C%22data%22:{}}; Domain=www.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = 'uslk_um_32867e=%2522c0c69f6e-d27e-484e-816c-2387c615b8f9%2522~%2522c9b3ebb6-d36e-4f0e-a903-bdd8b26ff3ae%2522~%2522%2522~%2522%2522~%2522%2522~false; Domain=www.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = 'JSESSIONID=43CE533D3123DD98668EFFF2316FAA6A; Domain=www.frankfurt-school.de; SameSite=Lax; Secure';

        //.frankfurt-school.de Domain

        document.cookie = 'uslk_umm_32867_s=\t{%22version%22:%221%22%2C%22data%22:{%22reauth%22:false}}; SameSite=Lax; Secure';


        // Blog Site Cookies
        // Domain = blog.frankfurt-school.de
        document.cookie = 'pvc_visits[0]=1597300632b21977a1597233090b22987a1597233626b6139; Domain=blog.frankfurt-school.de; SameSite=None; Secure';
        document.cookie = 'wp-settings-1=libraryContent%3Dbrowse%26editor%3Dtinymce%26mfold%3Do; Domain=blog.frankfurt-school.de; SameSite=None; Secure';
        document.cookie = 'wp-settings-time-1=1597159000; SameSite=None; Domain=blog.frankfurt-school.de; Secure';
        document.cookie = 'qtrans_front_language=en; SameSite=None; Domain=blog.frankfurt-school.de; Secure';

        //ExecEd Site Cookies
        //Domain = execed.frankfurt-school.de

        document.cookie = 'JSESSIONID=3AD42CB1B2E345B8CBE6DAEF33C5DAFC; Domain=execed.frankfurt-school.de; SameSite=Strict';
        document.cookie = 'uslk_umm_32867_c={%22version%22:%221%22%2C%22data%22:{}}; Domain=execed.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = 'uslk_um_32867e=%2522c0c69f6e-d27e-484e-816c-2387c615b8f9%2522~%2522c9b3ebb6-d36e-4f0e-a903-bdd8b26ff3ae%2522~%2522%2522~%2522%2522~%2522%2522~false; Domain=execed.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = 'uslk_umm_32867_s={%22version%22:%221%22%2C%22data%22:{%22reauth%22:false}}; Domain=execed.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = 'uslk_e=YzZkYzZiNTItMzY2MC03NTVlLTQxZjctYzAzZGJiODc1MjM3~~~~~~~~; Domain=execed.frankfurt-school.de; SameSite=Lax; Secure';

});

