    $(document).ready(function() {
        $('.fold-exec-ed-content-template').click(function() {
            console.log('I m clicked');
            var parent = $(this).parent();
            parent.toggleClass('-is-open');
            if (parent.hasClass('-is-open')) {
                parent.removeClass('-is-open');
            } else {
                parent.addClass('-is-open');
            }

        });
    });