$(document).ready(function() {

    /////////////////////////////////////////////
    // set up owl carousel - home page slider
    /////////////////////////////////////////////
    const interviewSlider = $("#programmeInterviewInternal");
    const externalTexts = $("#programmeInterviewTexts li");
    let externalTextsCounter = 1;

    // set up slider
    interviewSlider.owlCarousel({
        items: 1,
        loop: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 4200,
        smartSpeed: 900
    });

    // slider arrows
    $("#programmeInterviewInner .fa-caret-left").click(function () {
        interviewSlider.trigger('prev.owl.carousel');
    });
    $("#programmeInterviewInner .fa-caret-right").click(function () {
        interviewSlider.trigger('next.owl.carousel');
    });

    // init "translated" event of the slider
    interviewSlider.on('translated.owl.carousel', function() {
        externalTexts.css("display", "none");
        externalTexts.eq(externalTextsCounter).css("display", "block");
        externalTextsCounter++;
        if(externalTextsCounter === externalTexts.length){
            externalTextsCounter = 0;
        }
    });

});