$(document).ready(function() {

    if($(".testingAnimate").length > 0) {

        function closeBlock() {
            const theOpenBox = document.getElementsByClassName('openBigIcon');
            //Array.from(theOpenBox)[0].classList.remove('openBigIcon');
            theOpenBox[0].querySelector(".singleBigIconInner_2").style.display = 'none';
            theOpenBox[0].classList.remove('openBigIcon');
        }

        // add click event
        // open a block
        const classname = document.getElementsByClassName("singleBigIconInner_1");

        Array.from(classname).forEach(function (element) {
            element.addEventListener('click', function () {

                // detect
                // is there open block
                // close it
                if(document.getElementsByClassName('openBigIcon').length > 0){
                    closeBlock();
                    return;
                }

                element.parentElement.classList.add("openBigIcon");
                setTimeout(function () {
                    element.parentElement.querySelector(".singleBigIconInner_2").style.display = 'inline-block';
                }, 300);

            });
        });


        // add click event to close btn
        const mainContainer = document.getElementById("bigIconBlock");
        const closeButtons = mainContainer.getElementsByClassName("far");
        const singleBigIconInner_1  = mainContainer.getElementsByClassName("singleBigIconInner_1");

        Array.from(closeButtons).forEach(function (element) {
            element.addEventListener('click', function () {
                // close open box
                closeBlock();
            });
        });

        Array.from(closeButtons).forEach(function (element) {
            element.addEventListener('click', function () {
                // close open box
                closeBlock();
            });
        });




        ///////////////////////
        // for-companies


        ///////////////////////
        // for-companies-1

    }
    if($(".testingAnimate").length > 0) {

        function closeBlock() {
            const theOpenBox = document.getElementsByClassName('openBigIcon');
            //Array.from(theOpenBox)[0].classList.remove('openBigIcon');
            theOpenBox[0].querySelector(".singleBigIconInner_2").style.display = 'none';
            theOpenBox[0].classList.remove('openBigIcon');
        }

        // add click event
        // open a block
        const classname = document.getElementsByClassName("singleBigIconInner_1");

        Array.from(classname).forEach(function (element) {
            element.addEventListener('click', function () {

                // detect
                // is there open block
                // close it
                if(document.getElementsByClassName('openBigIcon').length > 0){
                    closeBlock();
                    return;
                }

                element.parentElement.classList.add("openBigIcon");
                setTimeout(function () {
                    element.parentElement.querySelector(".singleBigIconInner_2").style.display = 'inline-block';
                }, 300);

            });
        });


        // add click event to close btn
        const mainContainer = document.getElementById("bigIconBlock");
        const closeButtons = mainContainer.getElementsByClassName("far");
        const singleBigIconInner_1  = mainContainer.getElementsByClassName("singleBigIconInner_1");

        Array.from(closeButtons).forEach(function (element) {
            element.addEventListener('click', function () {
                // close open box
                closeBlock();
            });
        });

        Array.from(closeButtons).forEach(function (element) {
            element.addEventListener('click', function () {
                // close open box
                closeBlock();
            });
        });




        ///////////////////////
        // for-companies


        ///////////////////////
        // for-companies-1

    }

    if (window.location.pathname.includes("/home/individuals/companies")) {
        $(function() {
            $('#inhouse-dropdown-container').hover(function() {
                openMenu(true);
            }, function() {
                // on mouseout
                openMenu(false);
            });

            $(".inhouse-product").click(function() {
                const element = $(this).find('.inhouse-product-additionalInfo');
                const iconElement = $(this).find('.inhouse-product-icon-arrow');

                if(element.hasClass("inhouse-product-open")) {
                    // hide
                    element.css("display", "none");
                    iconElement.css("transform", "translateX(0px) rotate(0deg)");
                    element.removeClass("inhouse-product-open");
                } else {
                    // show
                    element.css("display", "block");
                    iconElement.css("transform", "translateX(0px) rotate(180deg)");
                    element.addClass("inhouse-product-open");
                }
            })
        });

        updateResults(true);

        $('#inhouse-search').keyup(function() {
            const val = $.trim($(this).val()).replace(/\s+/g, '').toLowerCase();
            const className = "inhouse-product";
            const elements = document.getElementsByClassName(className);

            // set counter for pagination
            // create new pagination
            if (val.length > 2) {
                const rowsTextArray = [];
                $.each(elements, function (index, el) {
                        rowsTextArray[index] = ($(this).find('.inhouse-product-title-a').text() + $(this).find('.inhouse-product-info').text())
                            .replace(/\s+/g, '')
                            .toLowerCase();
                });
                rowsTextArray.sort((a,b) => {a.localeCompare(b)})
                var counter = 0;
                $.each(elements, function (index, el) {
                    if (rowsTextArray[index].indexOf(val) === -1) {
                        $(this).css("display", "none");
                        $(this).attr("data-page","500");
                    } else {
                        $(this).css("display", "block");
                        let count = Math.ceil((counter + 1) / 10);
                        $(this).attr("data-page", count);
                        counter++;
                    }
                });

                var result = Paging(1, 10, counter, "inhouse-page-pagination-btn", "inhouse-page-pagination-btn-active");
                $('#inhouse-paginationBottom').html(result);
                updatePaginationResults(1);
                $("#inhouse-paginationBottom").on("click", "a", function () {
                    var result = Paging($(this).attr("pn"), 10, counter, "inhouse-page-pagination-btn", "inhouse-page-pagination-btn-active");
                    updatePaginationResults($(this).attr("pn"));
                    $("#inhouse-paginationBottom").html(result);
                });
            } else {
                $.each(elements, function() {
                    $(this).css("display", "block")
                });
                updateResults(false);
                updateResults(true);
            }
        });
    }
});

function setDefaultValuesForOpenElements() {
    const inhouseProducts = $(".inhouse-product");
    inhouseProducts.each(function () {
        const element = $(this).find('.inhouse-product-additionalInfo');
        const iconElement = $(this).find('.inhouse-product-icon-arrow');
        element.css("display", "none");
        iconElement.css("transform", "translateX(0px) rotate(0deg)");
        element.removeClass("inhouse-product-open");
    });
}

function openMenu(isOpen) {
    if (isOpen) {
        document.getElementById('inhouse-dropdown').style.display = "block";
    } else {
        document.getElementById('inhouse-dropdown').style.display = "none";
    }
}

function loadFilter(element) {
    $("#inhouse-search").val('');
    openMenu(false);
    setDefaultValuesForOpenElements();
    updateResults(false);
    document.getElementById('inhouse-dropdown-menu-title').innerHTML = element.innerHTML;
    document.getElementById('inhouse-dropdown-menu-title').dataset.selectedTopic = element.dataset.topicId;
    updateResults(true);
}

function hideAll() {
    var rows = $('.inhouse-product');
    $.each(rows, function () {
        $(this).css("display", "none");
    });
}

function updateResults(isShown) {
    if(isShown) {
        const className = "inhouse-" + document.getElementById('inhouse-dropdown-menu-title').dataset.selectedTopic;
        const elements = document.getElementsByClassName(className);
        var counter = 0;

        $.each(elements, function (index, el) {
            $(this).css("display", "block");
            counter++;
        });

        $("#inhouse-paginationBottom").off('click');

        var result = Paging(1, 10, counter, "inhouse-page-pagination-btn", "inhouse-page-pagination-btn-active");
        $('#inhouse-paginationBottom').html('');
        $('#inhouse-paginationBottom').html(result);
        updatePageIds();
        updatePaginationResults(1);
        $("#inhouse-paginationBottom").on("click", "a", function () {
            var result = Paging($(this).attr("pn"), 10, counter, "inhouse-page-pagination-btn", "inhouse-page-pagination-btn-active");
            updatePaginationResults($(this).attr("pn"));
            $("#inhouse-paginationBottom").html(result);
            $([document.documentElement, document.body]).animate({
                scrollTop: ($("#inhouse-dropdown-container").offset().top - 180)
            }, 500);
        });
    } else {
        const className = "inhouse-" + document.getElementById('inhouse-dropdown-menu-title').dataset.selectedTopic;
        const elements = document.getElementsByClassName(className);
        $.each(elements, function (index, el) {
            $(this).css("display", "none");
        });
    }
}

function updatePageIds() {
    const className = "inhouse-" + document.getElementById('inhouse-dropdown-menu-title').dataset.selectedTopic;
    const elements = document.getElementsByClassName(className);
    var allRows = $('.inhouse-product');
    allRows.each(function(index, el) {
        $(this).attr("data-page","500");
    });

    $.each(elements, function (index, el) {
        let count = Math.ceil((index + 1) / 10);
        $(this).attr("data-page", count);
    });
}

function updatePageIdsSearch() {
    const className = "inhouse-" + document.getElementById('inhouse-dropdown-menu-title').dataset.selectedTopic;
    const elements = document.getElementsByClassName(className);
    var allRows = $('.inhouse-product');
    allRows.each(function(index, el) {
        $(this).attr("data-page","500");
    });

    $.each(elements, function (index, el) {
        let count = Math.ceil((index + 1) / 10);
        $(this).attr("data-page", count);
    });
}

// add check for topic id
// add check for word search
function updatePaginationResults(lastSelectedItem) {
    var allRows = $('.inhouse-product');
    allRows.each(function() {
        if($(this).attr("data-page") == lastSelectedItem) {
            $(this).css("display","block");
        } else {
            $(this).css("display", "none");
        }
    });
}

function Paging(PageNumber, PageSize, TotalRecords, ClassName, DisableClassName) {
    var ReturnValue = "";
    var arrowsClass = "inhouse-pagination-prev-next";

    var TotalPages = Math.ceil(TotalRecords / PageSize);
    if (+PageNumber > 1) {
        if (+PageNumber == 2)
            ReturnValue = ReturnValue + "<a pn='" + (+PageNumber - 1) + "' class='" + arrowsClass + "'><i class=\"fal fa-chevron-left\"></i></a>";
        else {
            ReturnValue = ReturnValue + "<a pn='";
            ReturnValue = ReturnValue + (+PageNumber - 1) + "' class='" + arrowsClass + "'><i class=\"fal fa-chevron-left\"></i></a>";
        }
    } else
        ReturnValue = ReturnValue + "<span class='" + arrowsClass + "'><i class=\"fal fa-chevron-left\"></i></span>";
    if ((+PageNumber - 3) > 1)
        ReturnValue = ReturnValue + "<a pn='1' class='" + ClassName + "'>1</a><div class='inhouse-page-pagination-btn-dots'>...</div>";
    for (var i = +PageNumber - 3; i <= +PageNumber; i++)
        if (i >= 1) {
            if (+PageNumber != i) {
                ReturnValue = ReturnValue + "<a pn='";
                ReturnValue = ReturnValue + i + "' class='" + ClassName + "'>" + i + "</a>";
            }
            else {
                ReturnValue = ReturnValue + "<span class='" + DisableClassName + "'>" + i + "</span>";
            }
        }
    for (var i = +PageNumber + 1; i <= +PageNumber + 3; i++)
        if (i <= TotalPages) {
            if (+PageNumber != i) {
                ReturnValue = ReturnValue + "<a pn='";
                ReturnValue = ReturnValue + i + "' class='" + ClassName + "'>" + i + "</a>";
            }
            else {
                ReturnValue = ReturnValue + "<span class='" + DisableClassName + "'>" + i + "</span>";
            }
        }
    if ((+PageNumber + 3) < TotalPages) {
        ReturnValue = ReturnValue + "<div class='inhouse-page-pagination-btn-dots'>...</div><a pn='";
        ReturnValue = ReturnValue + TotalPages + "' class='" + ClassName + "'>" + TotalPages + "</a>";
    }
    if (+PageNumber < TotalPages) {
        ReturnValue = ReturnValue + "<a pn='";
        ReturnValue = ReturnValue + (+PageNumber + 1) + "' class='" + arrowsClass + "'><i class=\"fal fa-chevron-right\"></i></a>";
    }
    else
        ReturnValue = ReturnValue + "<span class='" + arrowsClass + "'><i class=\"fal fa-chevron-right\"></i></span>";

    return (ReturnValue);
}