$(document).ready(function() {
    let functionWasInvoked = false;

    function verticalAlign() {
        // vertical align the bar
        const barHeight = $("#homePageSliderBar").height();
        const windowHeight = window.innerHeight;

        $("#homePageSliderBar").animate({
            top: (windowHeight - barHeight)/2 + "px"
        }, 150);
        functionWasInvoked = false;
    }

    $( window ).resize(function() {
        if(!functionWasInvoked) {
            functionWasInvoked = true;

            setTimeout(function(){
                verticalAlign();
            }, 800);
        }
    });

    verticalAlign();
});
