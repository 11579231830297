$(document).ready(function() {

    if ($('body').width() < 766) {
        var herospaceImgHeight = $('#herospaceImg').height();
        if (herospaceImgHeight) {
            var herospaceContainerHeight = $('#herospaceContainer').height();
            var breadcrumbsInnerHeight = $('#breadcrumbsInner').height();
            var breadcrumbsElement = $('#breadcrumbsInner');
            var heroSpaceHeightDifference = herospaceImgHeight - herospaceContainerHeight ;

            var topAdjustment = (heroSpaceHeightDifference - breadcrumbsInnerHeight) / 2 ;
            if (topAdjustment > 0) {
                breadcrumbsElement.css('top', topAdjustment + 'px');
            }
        }

        var breadcrumbsContainerHeightClass = $('.breadcrumb-container').height();
        if (breadcrumbsContainerHeightClass) {
            var breadcrumbsOuterHeight = $('#breadcrumbsOuter').height();
            var breadcrumbsHeightDifference = breadcrumbsOuterHeight - breadcrumbsContainerHeightClass ;
            console.log("breadcrumbsOuterHeight: " + breadcrumbsOuterHeight);
            console.log("breadcrumbsContainerHeightClass: " + breadcrumbsContainerHeightClass)
            var topAdjustment = (breadcrumbsHeightDifference) / 2 ;
            console.log("topAdjustment: " + topAdjustment);
            var breadcrumbContainer = $('.breadcrumb-container');
            if (topAdjustment > 0) {
                breadcrumbContainer.css('top', topAdjustment + 'px');
            }
        }
    }

    // Remove the below commented code as it will bugg the phone resolutions for sure
    // due to known issues on

    // window.onresize = function() {
    //     if (window.innerWidth >= 1400) {
    //         topAdjustment = (heroSpaceHeightDifference - breadcrumbsInnerHeight) / 2 ;
    //         breadcrumbsElement.css('top', topAdjustment + 'px');
    //     }else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
    //         topAdjustment = (heroSpaceHeightDifference - breadcrumbsInnerHeight) / 2 ;
    //         breadcrumbsElement.css('top', topAdjustment + 'px');
    //     } else if (window.innerWidth < 1200 && window.innerWidth >= 1022) {
    //         topAdjustment = (heroSpaceHeightDifference - breadcrumbsInnerHeight) / 2 ;
    //         breadcrumbsElement.css('top', topAdjustment + 'px');
    //     } else if (window.innerWidth < 1022 && window.innerWidth >= 766) {
    //         topAdjustment = (heroSpaceHeightDifference - breadcrumbsInnerHeight) / 2 ;
    //         breadcrumbsElement.css('top', topAdjustment + 'px');
    //     } else if (window.innerWidth < 766 && window.innerWidth >= 478) {
    //         topAdjustment = (heroSpaceHeightDifference - breadcrumbsInnerHeight) / 2 ;
    //         breadcrumbsElement.css('top', topAdjustment + 'px');
    //     } else if (window.innerWidth < 478 && window.innerWidth >= 350) {
    //         topAdjustment = (heroSpaceHeightDifference - breadcrumbsInnerHeight) / 2 ;
    //         breadcrumbsElement.css('top', topAdjustment + 'px');
    //     } else {
    //         topAdjustment = (heroSpaceHeightDifference - breadcrumbsInnerHeight) / 2 ;
    //         breadcrumbsElement.css('top', topAdjustment + 'px');
    //     }
    // };
});

