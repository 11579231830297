$(document).ready(function() {
    const owlLightBox = $('#execEd-lightbox-gallery');
    const externalTexts = $('#sliderOuterTexts li');
    let externalTextsCounter = 0;
    if (owlLightBox.length > 0) {

        // set up slider
        owlLightBox.owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplayHoverPause: true,
            autoplayTimeout: 6200,
            smartSpeed: 900
        });

        $('.executive-lightbox-container').css('display','none');
        // init "translated" event of the slider
        owlLightBox.on('translated.owl.carousel', function(event) {
            externalTexts.css('display', 'none');
            externalTexts.eq(externalTextsCounter).css('display', 'block');
            externalTextsCounter++;
            if (externalTextsCounter > 2) {
                externalTextsCounter = 0;
            }
        });

        // slider arrows
        $('.executive-lightbox-navigation-previous-item').click(function() {
            owlLightBox.trigger('prev.owl.carousel');
        });
        $('.executive-lightbox-navigation-next-item').click(function() {
            owlLightBox.trigger('next.owl.carousel');
        });

        // vertical align - center
        // the texts in slider
        function positionSliderTitles() {
            const sliderContainer = $('#execEd-lightbox-gallery').height();

            $('#execEd-lightbox-gallery h1').each(function() {
                const element = $(this)

                element.css('paddingTop', (sliderContainer - element.height()) / 2 + 'px');
            });
        }

        // invoke vertical align onload
        positionSliderTitles();

        /////////////////////////////////
        // invoke design recalculations
        // on window resize
        $(window).resize(function() {
            positionSliderTitles();
        });
    }
    $('.executive-image').click(function() {
        var imageGalleryIndex = $(this).data('imageGalleryIndex');
        var galleryStartPos = imageGalleryIndex - 1;
        owlLightBox.trigger('to.owl.carousel',[galleryStartPos]);
        $('.executive-lightbox-container').css('display','block');
        $('.executive-lightbox-container').css('visibility','visible');
    });
    $('.executive-gallery-link .content').click(function() {
        $('.executive-lightbox-container').css('display','block');
        $('.executive-lightbox-container').css('visibility','visible');
    });
    $('#closeLightBoxButton').click(function() {
        $('.executive-lightbox-container').css('display','none');
    });

});

