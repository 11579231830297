// $(document).ready(function() {
//     let pageId = $(".execed-cta-bar-container").data("contentId");
//     if (!pageId) {
//         return;
//     }
//     initCtaBar(pageId);
//
//
//     $(".execed-cta-bar-close-link").click(function () {
//         closeCtaBar(pageId);
//
//     });
// });
//
// function closeCtaBar(pageId) {
//     sessionStorage.setItem("acknowledgedCta" + pageId, true);
//     $(".execed-cta-bar-container").css("display", "none");
// }
//
// function initCtaBar(pageId) {
//     // if cookie banner is shown, don't show cta
//     if (localStorage.getItem("alreadyAcceptedCookies")) {
//         if (!sessionStorage.getItem("acknowledgedCta" + pageId)) {
//             $(".execed-cta-bar-container").css("display", "block");
//         } else {
//             $(".execed-cta-bar-container").css("display", "none");
//         }
//     } else {
//         $(".execed-cta-bar-container").css("display", "none");
//     }
// }