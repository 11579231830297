$(document).ready(function() {
    const owlBlogSlider = $('.exec-content-page .blog-entries');
    const externalTexts = $('#sliderOuterTexts li');
    let externalTextsCounter = 0;
    if (owlBlogSlider.length > 0) {
        // set up slider
        owlBlogSlider.owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplayTimeout: 6200,
            smartSpeed: 900
        });

        // init "translated" event of the slider
        owlBlogSlider.on('translated.owl.carousel', function(event) {
            externalTexts.css('display', 'none');
            externalTexts.eq(externalTextsCounter).css('display', 'block');
            externalTextsCounter++;
            if (externalTextsCounter > 2) {
                externalTextsCounter = 0;
            }
        });

        // slider arrows
        $('.exec-blogslider-prev').click(function() {
            owlBlogSlider.trigger('prev.owl.carousel');
        });
        $('.exec-blogslider-next').click(function() {
            owlBlogSlider.trigger('next.owl.carousel');
        });

        // vertical align - center
        // the texts in slider
        function positionSliderTitles() {
            const sliderContainer = $('.exec-content-page .blog-entries').height();

            $('.exec-content-page .blog-entries h1').each(function() {
                const element = $(this)

                element.css('paddingTop', (sliderContainer - element.height()) / 2 + 'px');
            });
        }

        // invoke vertical align onload
        positionSliderTitles();

        // ///////////////////////////////
        // invoke design recalculations
        // on window resize
        $(window).resize(function() {
            positionSliderTitles();
        });
    }
});