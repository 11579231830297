$(document).ready(function () {

    /////////////////////////////////////////////
    // course selector drop-downs
    /////////////////////////////////////////////
    function markDropdownElement(clickedEL) {
        clickedEL.addClass("clickedEl");
        clickedEL.find("i").css("visibility", "visible");
        clickedEL.find(".dropdown-title").css("color", "#79a342");
    }

    function unmarkDropdownElement(clickedEL) {
        clickedEL.removeClass("clickedEl");
        clickedEL.find("i").css("visibility", "hidden");
        clickedEL.find(".dropdown-title").css("color", "#666");
    }

    $(".homePageSliderSearch_dropdown li").click(function () {
        const clickedEL = $(this);
        //const clickedElParent = clickedEL.parent();

        if (clickedEL.hasClass("clickedEl")) {
            unmarkDropdownElement(clickedEL);

            //clickedElParent.attr("data-value", "");
        } else {
            markDropdownElement(clickedEL);

            //const clickedElementValue = clickedEL.find("p").text();
            //clickedElParent.attr("data-value", clickedElementValue);
        }
    });

    ////////////////////////////////////
    // open/close drop-downs on mobile
    function mobileDropDowns() {
        if(window.screen.width < 1024){
            $(".homePageSliderSearch_greyBG").click(function() {
                const parentElement = $(this).parent();
                const dropDown = parentElement.find(".homePageSliderSearch_dropdown");
                const dropDownArrow = parentElement.find(".fas");

                $(".homePageSliderSearch_dropdown").css("display", "none");

                if(dropDown.hasClass("itIsOpen")){
                    dropDown.css("display", "none");
                    dropDown.removeClass("itIsOpen");
                    dropDownArrow.css({ transition: '0.4s', transform: 'rotate(0)' });
                }else{
                    dropDown.css("display", "block");
                    dropDown.addClass("itIsOpen");
                    dropDownArrow.css({ transition: '0.4s', transform: 'rotate(180)' });
                }
            });
        }
    }

    mobileDropDowns();


    ////////////////////////////////////////////
    // submit data from the search fields
    $("#homePageSliderSearch_submitButton").click(function() {
        $(".homePageSliderSearch_dropdown").each(function() {
            const dropDownName = $(this).data("name");
            const dropDownValue = $(this).data("value");

            $("#homePageSlider input[name='"+ dropDownName +"']").val(dropDownValue);
        });

        /// fields validation

        $("#homePageSlider form").submit();
    });

    //////////////////////////////////////////
    // invoke design recalculations on window resize
    $(window).resize(function(){
        mobileDropDowns();
    });

    ///////////////////////////////////////////
    //
    function closeAllDropDowns(){
        $('.openSubMenu').each(function() {
            const clickedEL = $(this);

            console.log(clickedEL);
            const parentElement = clickedEL.parent();
            const theDropDown = parentElement.find('.homePageSliderSearch_dropdown');
            const arrowIcon = clickedEL.find('i');

            theDropDown.css('display', 'none');
            parentElement.removeClass("openSubMenu");
            arrowIcon.css({ transition: '0.4s', transform: 'rotate(0)' });
        });
    }

    $(".homePageSliderSearch_greyBG").click(function() {
        const clickedEL = $(this);
        const parentElement = clickedEL.parent();
        const theDropDown = parentElement.find('.homePageSliderSearch_dropdown');
        const arrowIcon = clickedEL.find('i');
        console.log(arrowIcon);

        if(parentElement.hasClass("openSubMenu")){
            // close the sub menu
            theDropDown.css('display', 'none');
            parentElement.removeClass("openSubMenu");
            arrowIcon.css({ transition: '0.4s', transform: 'rotate(0)' });
        }else{
            // close any open drop down
            closeAllDropDowns();
            // open the sub menu
            theDropDown.css('display', 'block');
            parentElement.addClass("openSubMenu");
            arrowIcon.css({ transition: '0.4s', transform: 'rotate(180deg)' });
        }

    });
});