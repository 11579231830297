$(document).ready(function() {
    const owlVideoGallery = $(".executive-video-gallery");
    const externalTexts = $("#sliderOuterTexts li");
    let externalTextsCounter = 0;
    if (owlVideoGallery.length > 0) {
        console.log("vid");

        // set up slider
        owlVideoGallery.owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplayTimeout: 6200,
            smartSpeed: 900
        });

        // init "translated" event of the slider
        owlVideoGallery.on('translated.owl.carousel', function(event) {
            externalTexts.css("display", "none");
            externalTexts.eq(externalTextsCounter).css("display", "block");
            externalTextsCounter++;
            if(externalTextsCounter > 2){
                externalTextsCounter = 0;
            }
        });

        // slider arrows
        $(".executive-video-gallery-prev").click(function () {
            owlVideoGallery.trigger('prev.owl.carousel');
        });
        $(".executive-video-gallery-next").click(function () {
            owlVideoGallery.trigger('next.owl.carousel');
        });

        // vertical align - center
        // the texts in slider
        function positionSliderTitles() {
            const sliderContainer = $("#executiveVideoGallery").height();

            $("#executiveVideoGallery h1").each(function () {
                const element = $(this)

                element.css("paddingTop", (sliderContainer - element.height())/2 + "px");
            });
        }

        // invoke vertical align onload
        positionSliderTitles();

        /////////////////////////////////
        // invoke design recalculations
        // on window resize
        $(window).resize(function(){
            positionSliderTitles();
        });
    }
});