$(document).ready(function() {
    if($("#header-OS").length > 0 ) {

        const menuType = $("#header-OS").css("display") == "none" ? "mobileHeader" : "header-OS";
        const submenuHeight = $("#pageInternalMenuSeminar").outerHeight() + $("#singleProfileHeader").outerHeight() + $("#" + menuType).outerHeight();

        $(".anchor").css("height", submenuHeight);
        $(".anchor").css("margin-top", -submenuHeight);

        /////////////////////
        // change the height of the header menu bar
        // on scroll (is it on top or not)
        /////////////////////
        if($("#pageInternalMenuSeminar").length > 0) {
            const submenuOffset = $("#pageInternalMenuSeminar").offset().top;

            $(window).scroll(function () {
                if (submenuOffset < $("#tracking-pixel").offset().top + $("#" + menuType).outerHeight()) {
                    $("#pageInternalMenuSeminar").addClass("stickySubmenu-OS");
                    // $("#topicsPage_blogFeed_text").addClass("sticky-submenu-changed")
                    $("#emptyInternalMenuSeminar").css("display", "block");
                } else if (submenuOffset > $("#tracking-pixel").offset().top + $("#" + menuType).outerHeight()) {
                    $("#pageInternalMenuSeminar").removeClass("stickySubmenu-OS");
                    // $("#topicsPage_blogFeed_text").removeClass("sticky-submenu-changed")
                    $("#emptyInternalMenuSeminar").css("display", "none");
                }
            });
        }

        // function attached to hashchange event
        // checks the pageYoffset and corrects the scroll position
        // so that it doesn't scroll past the designated content title.



        // Cache selectors
        var topMenu = $("#pageInternalMenuSeminar"),
            topMenuHeight = topMenu.outerHeight()+100,
            // All list items
            menuItems = topMenu.find("a"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function(){
                var item = $($(this).attr("href"));
                if (item.length) { return item; }
            });

        $("#emptyInternalMenuSeminar").css("height", topMenu.outerHeight());


        // Bind to scroll
        $(window).scroll(function() {
            // Get container scroll position

            // if body has class product only
            if ($('body').hasClass('product')) {
                var topPosition = $(this).scrollTop()
                var fromTop = $(this).scrollTop() + topMenuHeight;

                // Get id of current scroll item
                var cur = scrollItems.map(function () {
                    if ($(this).offset().top < fromTop)
                        return this;
                });
                // Get the id of the current element
                cur = cur[cur.length - 1];
                var id = cur && cur.length ? cur[0].id : "";

                // Set/remove active class
                for (var i = 0; i < menuItems.length; i++) {
                    menuItems[i].classList.remove("active-menu");
                    if (menuItems[i].getAttribute("href") === ("#" + id)) {
                        menuItems[i].classList.add("active-menu");
                    }
                }
                // remove the hash, if you scroll back to top
                if (topPosition <= 1) {
                    location.hash = '';
                }
            }
        });



        function hashSeminarHandlerOS() {
            setTimeout(function() {
                $([document.documentElement, document.body]).animate({
                    scrollTop: window.pageYOffset - $("#pageInternalMenuSeminar").outerHeight()
                }, 400);
            }, 100);
        }

        window.addEventListener('hashchange', hashSeminarHandlerOS, false);
    }
    // window.addEventListener('hashchange', hashSeminarHandler, false);

});