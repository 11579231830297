$(document).ready(function() {

    // Foldout functionality for the event table descriptions

    $('.execEd-eventTable-row-main-container').click(function() {
        var parent = $(this).parent();
        var descriptionContainer = parent.find('.execEd-eventTable-row-description-container');
        var allDescriptionContainers = $('.execEd-eventTable-row-description-container');

        if (descriptionContainer.hasClass('-event-description-open')) {
            descriptionContainer.removeClass('-event-description-open');
        } else {
            allDescriptionContainers.each(function() {
                $(this).removeClass('-event-description-open');
            });
            descriptionContainer.addClass('-event-description-open');
        }

    });
    // get all Rows involved for the pagination
    var allRows = $(".excecEd-eventTable-row");

    // init pagination data index
    allRows.each(function(){
        if($(this).data("eventtableRowindex") == 1) {
            $(this).css("display","block");
        } else {
            $(this).css("display", "none");
        }
    });
    // Pagination functionality .
    var paginationContainer = $('#execEd-eventTable-pagination-container');
    var paginationLength = paginationContainer.data('paginationLength');

    for (var i = 1; i <= paginationLength ; i++) {
        paginationContainer.append("<div class='execEd-eventTable-pagination-btn' data-pagination-item=" + i + ">" + i + "</div>");
    }

    var paginationItems = $(".execEd-eventTable-pagination-btn");
    paginationItems.each(function() {
        var paginationItem = $(this).data("paginationItem");

        $(this).click(function() {
            var paginationItem = $(this).data("paginationItem");
            allRows.each(function() {
                var rowIndex = $(this).data("eventtableRowindex");

                if($(this).data("eventtableRowindex") == paginationItem) {
                    $(this).css("display","block");
                } else {
                    $(this).css("display", "none");
                }
            });
        });
    });

    $(".execEd-eventTable-pagination-btn").click(function(){
        var clickedEl = $(this);
            console.log("clc",clickedEl);
        if ($(".execEd-eventTable-pagination-btn").hasClass("isActivePagination")) {
            $(".execEd-eventTable-pagination-btn").removeClass("isActivePagination");
                clickedEl.addClass("isActivePagination");
        } else {
            clickedEl.addClass("isActivePagination");
        }
    });


    // wait for the element to be loaded fn

    function waitForElement(id, callback){
        var poops = setInterval(function(){
            $(".executive-spinner").css("display","block");
            if(document.getElementById(id)){
                clearInterval(poops);
                callback();
            }

        }, 100);
    }

    // executes when the eventTable is loaded to the DOM
    waitForElement("execEd-eventTable-pagination-container", function(){
        $(".executive-spinner").css("display","none");
    });

});
