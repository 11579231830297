$(document).ready(function () {

    /////////////////////////////////////////////
    // set up owl carousel - home page slider
    /////////////////////////////////////////////
    const owl = $("#sliderContainer");
    const externalTexts = $("#sliderOuterTexts li");
    const owlSlides = owl.children('div');
    const owlDots = $("#sliderContainer .owl-dots")
    console.log(owlDots,"Owl dots");
    console.log(owlSlides.length);
    let externalTextsCounter = 0;
    if (owl.length > 0) {
        owl.owlCarousel({
            items: 1,
            loop: owl.children().length > 1 ? true : false,
            nav: true,
            dots: true,
            touchDrag: owl.children().length > 1 ? true : false,
            mouseDrag: owl.children().length > 1 ? true : false,
            pullDrag: owl.children().length > 1 ? true : false,
            autoplay: owl.children().length > 1 ? true : false,
            autoplayHoverPause: true,
            autoplayTimeout: 6200,
            smartSpeed: 900
        });
        // externalTexts.eq(externalTextsCounter).css("display", "block");
        // externalTextsCounter++;
        // // init "translated" event of the slider
        // owl.on('translated.owl.carousel', function(event) {
        //     externalTexts.css("display", "none");
        //     console.log("Current Slide", externalTextsCounter);
        //     externalTexts.eq(externalTextsCounter).css("display", "block");
        //     externalTextsCounter++;
        //     console.log("Coming Slide", externalTextsCounter);
        //     if(externalTextsCounter > owlSlides.length - 1){
        //         externalTextsCounter = 0;
        //     }
        // });

        // slider arrows
        $(".navbar-prev").click(function () {
            owl.trigger('prev.owl.carousel');
        });
        $(".navbar-next").click(function () {
            owl.trigger('next.owl.carousel');
        });

        // vertical align - center
        // the texts in slider
        function positionSliderTitles() {
            const sliderContainer = $("#sliderContainer").height();

            $("#sliderContainer h1").each(function () {
                const element = $(this)

                element.css("paddingTop", (sliderContainer - element.height())/2 + "px");
            });

            // $("#sliderContainer .ctaButton").each(function () {
            //     let headingElement = $("#sliderContainer h1");
            //     const anchorElement = $(this)
            //     anchorElement.css("marginTop", (sliderContainer - (anchorElement.height() + headingElement.height())) / 2 + 20 +"px");
            // });
        }

        // invoke vertical align onload
        positionSliderTitles();

        /////////////////////////////////
        // invoke design recalculations
        // on window resize
        $(window).resize(function(){
            positionSliderTitles();
        });
    }



});
