$(document).ready(function() {
    // show cookie settings
    $("#execed-cookie-bar-show-modal-link").click(function () {
        $("#execed-cookie-bar-modal-dialog").removeClass("execed-cookie-bar-is-hidden");
    });

    $(".execed-cookie-bar-settings-link").click(function(){
        if($("#execed-cookie-bar-modal-settings-container").hasClass("execed-cookie-bar-is-hidden")) {
            $(".execed-cookie-bar-settings-link > span > svg").addClass("execed-rotate-cookie-chevron");
            $("#execed-cookie-bar-modal-settings-container").removeClass("execed-cookie-bar-is-hidden");
            $(".upper-close-link").css("visibility", "hidden");
        } else {
            $(".execed-cookie-bar-settings-link > span > svg").removeClass("execed-rotate-cookie-chevron");
            $("#execed-cookie-bar-modal-settings-container").addClass("execed-cookie-bar-is-hidden");
            $(".upper-close-link").css("visibility", "visible");
            $("..umm-aqmk2c").css("visibility", "visible");
        }

    });

    // accept
    $("#execed-cookie-bar-modal-save-link").click(function () {
        closeCookieBar();
        $("#execed-cookie-bar-container").addClass("execed-cookie-bar-is-hidden");

        if ($("#execed-cookie-bar-container").hasClass("execed-cookie-bar-is-hidden")) {
            $("body").css("overflow","initial");
        }
    });

    // Accept All Upper button
    $(".upper-close-link").click(function () {
        acceptAllCookies();
        $("#execed-cookie-bar-container").addClass("execed-cookie-bar-is-hidden");

        if ($("#execed-cookie-bar-container").hasClass("execed-cookie-bar-is-hidden")) {
            $("body").css("overflow","initial");
        }
    });

    // Accept All Upper button
    $("#execed-cookie-bar-modal-close-link").click(function () {
        acceptAllCookies();
        $("#execed-cookie-bar-container").addClass("execed-cookie-bar-is-hidden");

        if ($("#execed-cookie-bar-container").hasClass("execed-cookie-bar-is-hidden")) {
            $("body").css("overflow","initial");
        }
    });

    $('#execed-cookie-checkbox-1').change(function() {
        checkboxAnalitycsEvent();
    });

    $('#execed-cookie-checkbox-2').change(function() {
        checkboxNecessaryEvent();
    });

    init();

    let pageId = $(".execed-cta-bar-container").data("contentId");
    if (!pageId) {
        return;
    }



    $(".execed-cta-bar-close-link").click(function () {
        closeCtaBar(pageId);

    });

    // avoids scroll lock if on author instance where we don't show cookie bar
    if($(".execed-top-container").hasClass("author-execed-top-container")) {
        $(".execed-cta-bar-container").css("display", "block");
        $("body").css("overflow", "initial !important");
    }

    if (localStorage.getItem("acceptedCookies")) {
        $(".execed-cta-bar-container").css("display", "block");
        $("body").css("overflow", "initial !important");
    }





});

function showCookieBar() {
    checkboxNecessaryEvent();
    checkboxAnalitycsEvent();
    if (localStorage.getItem("acceptedCookies")) {
        $("body").css("overflow","initial");
    } else {
        // check if the cookie dialogue exists at all , hide overflow ONLY then
        if($("#execed-cookie-bar-modal-dialog").hasClass("public-exec-cookies")) {
            $("body").css("overflow","hidden");
            $("#execed-cookie-bar-modal-dialog").removeClass("execed-cookie-bar-is-hidden");
        }
    }
}

function init() {
    if (localStorage.getItem("enableGoogleTagManager") == null) {
        // localStorage.setItem("enableGoogleTagManager", true);
    }

    // If the user hasn't accepted already, show the notification layer
    if (localStorage.getItem("acceptedCookies")) {

    } else {
        showCookieBar();
    }
}

/**
 * Analitycs checbox
 */
function checkboxAnalitycsEvent() {
    if ($('#execed-cookie-checkbox-1').is(":checked")) {
        localStorage.setItem("enableGoogleTagManager", "true");
    } else {
        localStorage.setItem("enableGoogleTagManager", "false");
    }
}

function checkboxNecessaryEvent() {
    if($('#execed-cookie-checkbox-2').is(":checked")){
        localStorage.setItem("enableNecessaryCookies", "true");
    } else {
        localStorage.setItem("enableNecessaryCookies", "false");
    }
}

/**
 * Close the cookie bar
 */
function closeCookieBar() {
    $(".execed-cta-bar-container").css("display", "block");
    localStorage.setItem("acceptedCookies", "true");
    checkboxAnalitycsEvent();
    if(localStorage.getItem("enableGoogleTagManager")) {
        enableAnalytics();
    }
    if(localStorage.getItem("enableNecessaryCookies")){
        enableAdCookies();
    }
    $("body").css("overflow","initial");


    // initCtaBar(pageId);
}

function acceptAllCookies() {
    localStorage.setItem("enableGoogleTagManager", "true");
    localStorage.setItem("enableNecessaryCookies", "true");
    enableAnalytics();
    enableAdCookies();
    $(".execed-cookie-bar-container").addClass("execed-cookie-bar-is-hidden");
    localStorage.setItem("acceptedCookies", "true");
}

function enableAnalytics() {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-6W3Z');

    if (localStorage.getItem("enableNecessaryCookies") === "true") {
        consentAnalyticsGranted();
    }

        // Setting Google analytics and ads conversion tracking

        document.cookie = '_ga=GA1.2.210631174.1595409136; SameSite=Lax; Domain=.frankfurt-school.de; Secure';
        document.cookie = '_gid=GA1.1.1537083176.1597066877; Domain=.frankfurt-school.de; SameSite=Strict';
        document.cookie = '_gat_UA-145794476-1=1; Domain=.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = '_gcl_au=1.1.1117465804.1595409136; Domain=.frankfurt-school.de; SameSite=Lax; Secure';

        // setting hotjar cookies
        document.cookie = '_hjIncludedInCCSample=1; SameSite=Lax; Secure';
        document.cookie = '_hjIncludedInSample=1; SameSite=Lax; Secure';
        document.cookie = '_hjid=43f7240e-d268-4c71-a639-a8e949dec3ff; Domain=.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = '_hjIncludedInPageviewSample=1; Domain=execed.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = '_hjIncludedInSessionSample=1; Domain=execed.frankfurt-school.de; SameSite=Lax; Secure';
}

function enableAdCookies() {
    if(localStorage.getItem("enableGoogleTagManager") === "true") {
        document.cookie = '_uetvid=847a3fb7b2f30d2139a79187ca00998c; Domain=.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = '_uetsid=1c8ae53167e517af5153703ea9907490; Domain=.frankfurt-school.de; SameSite=Lax; Secure';
        document.cookie = '_dc_gtm_UA-37023095-1=1; SameSite=Lax; Secure';
        document.cookie = '_fbp=fb.1.1597066932928.541875977; Domain=.frankfurt-school.de; SameSite=Lax; Secure';
        consentAdGranted("granted");
    }
}


function closeCtaBar(pageId) {
    sessionStorage.setItem("acknowledgedCta" + pageId, true);
    $(".execed-cta-bar-container").css("display", "none");
}

function initCtaBar(pageId) {
    // if cookie banner is shown, don't show cta
    if (localStorage.getItem("acceptedCookies")) {
        if (!sessionStorage.getItem("acknowledgedCta" + pageId)) {
            $(".execed-cta-bar-container").css("display", "block");
            $("body").css("overflow", "initial !important");
        } else {
            $(".execed-cta-bar-container").css("display", "none");
        }
    } else {
        $(".execed-cta-bar-container").css("display", "none");
    }
}