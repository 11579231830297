$(document).ready(function() {
    // close the login pop-up
    $(".remodal-new .close-link").click(function () {
        $(".remodal-wrapper-new").animate({
            opacity: 0
        }, function () {
            $(this).css("display", "none")
        });
    });
});

// open the login pop-up
function openLoginPopup(){
    $(".remodal-wrapper-new").css("display", "block").animate({
       opacity: 1
    });
}