$(document).ready(function() {
    const owlTestimonials = $("#testimonialSliderContainer");
    const externalTexts = $("#sliderOuterTexts li");
    let externalTextsCounter = 0;
    if (owlTestimonials.length > 0) {
        console.log(333);

        // set up slider
        owlTestimonials.owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplayTimeout: 6200,
            smartSpeed: 900
        });

        // init "translated" event of the slider
        owlTestimonials.on('translated.owl.carousel', function(event) {
            externalTexts.css("display", "none");
            externalTexts.eq(externalTextsCounter).css("display", "block");
            externalTextsCounter++;
            if(externalTextsCounter > 2){
                externalTextsCounter = 0;
            }
        });

        // slider arrows
        $(".testimonial-prev").click(function () {
            owlTestimonials.trigger('prev.owl.carousel');
        });
        $(".testimonial-next").click(function () {
            owlTestimonials.trigger('next.owl.carousel');
        });

        // vertical align - center
        // the texts in slider
        function positionSliderTitles() {
            const sliderContainer = $("#testimonialSliderContainer").height();

            $("#testimonialSliderContainer h1").each(function () {
                const element = $(this)

                element.css("paddingTop", (sliderContainer - element.height())/2 + "px");
            });
        }

        // invoke vertical align onload
        positionSliderTitles();

        /////////////////////////////////
        // invoke design recalculations
        // on window resize
        $(window).resize(function(){
            positionSliderTitles();
        });
    }

});