$(document).ready(function() {
    /////////////////
    // init slider
    const slider = $("#topicsPage_institutesSlider");

    slider.owlCarousel({
        loop: true,
        autoplay: true,
        nav: false,
        mouseDrag: true,
        dots: false,
        responsive: {
            0: {
                items: 1,
                margin: 0,
            },
            766: {
                items: 2,
                margin: 20,
            },
            1022: {
                items: 2,
                margin: 30,
            },
            1200: {
                items: 2,
                margin: 30,
            },
            1400: {
                items: 2,
                margin: 30,
            }
        }
    });

    // set up nav bar (left/right)
    $("#topicsPage_institutes .fa-caret-left").click(function () {
        slider.trigger('prev.owl.carousel');
    });
    $("#topicsPage_institutes .fa-caret-right").click(function () {
        slider.trigger('next.owl.carousel');
    });

    let menuType = $("#header").css("display") == "none" ? "mobileHeader" : "header";
    if ($('body').hasClass('content')){
        menuType = $("#header-OS").css("display") == "none" ? "mobileHeader" : "header";
        console.log("Frankfurt content template !");
    }
    console.log(menuType,"Menu-type");
    const submenuHeight = $("#pageInternalMenu").outerHeight() + $("#" + menuType).outerHeight();
    $(".anchor").css("height", submenuHeight);
    $(".anchor").css("margin-top", -submenuHeight);


    /////////////////////
    // change the height of the header menu bar
    // on scroll (is it on top or not)
    /////////////////////
    if($("#pageInternalMenu").length > 0) {
        const submenuOffset = $("#pageInternalMenu").offset().top;

        $(window).scroll(function () {
            if (submenuOffset < $("#tracking-pixel").offset().top + $("#" + menuType).outerHeight()) {
                //console.log($("#" + menuType).outerHeight());
                $("#pageInternalMenu").addClass("stickySubmenu");
                $("#topicsPage_blogFeed_text").addClass("sticky-submenu-changed")
                $("#emptyInternalMenu").css("display", "block");
            } else if (submenuOffset > $("#tracking-pixel").offset().top + $("#" + menuType).outerHeight()) {
                $("#pageInternalMenu").removeClass("stickySubmenu");
                $("#topicsPage_blogFeed_text").removeClass("sticky-submenu-changed")
                $("#emptyInternalMenu").css("display", "none");
            }

        });

    }

    // function attached to hashchange event
    // checks the pageYoffset and corrects the scroll position
    // so that it doesn't scroll past the designated content title.
    function hashHandler() {
        var extraOffset = 10;
        if ($('body').hasClass('exec-content-page')) {
            extraOffset = 40;
        }
        setTimeout(function(){
            $([document.documentElement, document.body]).animate({
                scrollTop: window.pageYOffset - ($("#pageInternalMenu").outerHeight() + extraOffset)
            }, 400);
        }, 10);
    }

    window.addEventListener('hashchange', hashHandler, false);



    // Cache selectors
    var topMenu = $("#pageInternalMenu"),
        topMenuHeight = topMenu.outerHeight()+100,
        // All list items
        menuItems = topMenu.find("a"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
            var item = $($(this).attr("href"));
            if (item.length) { return item; }
        });

    $("#emptyInternalMenu").css("height", topMenu.outerHeight());
    console.log("top menu height: " + topMenu.outerHeight());

    // Bind to scroll
    $(window).scroll(function() {
        // Get container scroll position
        var fromTop = $(this).scrollTop() + topMenuHeight;

        // Get id of current scroll item
        var cur = scrollItems.map(function () {
            if ($(this).offset().top < fromTop)
                return this;
        });
        // Get the id of the current element
        cur = cur[cur.length - 1];
        var id = cur && cur.length ? cur[0].id : "";

        // Set/remove active class
        for (var i = 0; i < menuItems.length; i++) {
            menuItems[i].classList.remove("active-menu");
            if (menuItems[i].getAttribute("href") === ("#" + id)) {
                menuItems[i].classList.add("active-menu");
            }
        }
    });

    var sliderItems = $(".insert.-testimonial-slider .background .testimonial.item");
    var sliderContainer = $(".insert.-testimonial-slider .background");
    var checkEditmode = $(".mgnlEditorBar");

    var bigestHeight = 0;
    sliderItems.each(function () {
        var sliderEl = $(this).find(".content");
        if (sliderEl.height() > bigestHeight) {
            bigestHeight = sliderEl.outerHeight() + 98;
        }
    });
    if(checkEditmode) {
        sliderContainer.css("height",+100+"%");
    }
    else {
        sliderContainer.css("height", bigestHeight + "px");
    }
});
