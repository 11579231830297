$(document).ready(function() {

    if($("#mobileDirectoryLetters").length > 0) {

        function hasClass(element, cls) {
            return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
        }

        function searchForTextInTitles() {
            var searchForText = $("#directorySearchField input").val();
            searchForText = searchForText.toLowerCase();

            // match
            $(".singleContact").each(function () {
                const course = $(this);
                let courseTitle = course.children(".singleContactName").text();

                courseTitle = courseTitle.toLowerCase();

                // show / hide course
                if (courseTitle.includes(searchForText)) {
                    // show course
                    course.css("display", "inline-block");
                } else {
                    // hide course
                    course.css("display", "none");
                }

            });
        }

        function searchByLetter(searchLetter){
            searchLetter = searchLetter.toLowerCase();

            $(".singleContact").each(function () {
                const course = $(this);
                let courseTitle = course.children(".singleContactName").text();
                courseTitle = courseTitle.toLowerCase();
                const separateWords = courseTitle.split(" ");
                const lastName = separateWords.slice(-1)[0];

                // show / hide course
                if (lastName[0] === searchLetter) {
                    // show course
                    course.css("display", "inline-block");
                } else {
                    // hide course
                    course.css("display", "none");
                }

            });
        }

        // click on search icon
        $("#directorySearchField .fa-search").click(function () {
            searchForTextInTitles();
        });

        // click on clear icon
        $("#directorySearchField .fa-times").click(function () {
            $("#directorySearchField input").val("");
            searchForTextInTitles();
            $("#directorySearchField .fa-times").css('opacity', 0);
        });

        // detect key pressing
        $(document).keyup(function (e) {
            // if there is a letter in the input
            // show clear button "X"
            let inputContent = $("#directorySearchField input").val();

            switch(e.keyCode){
                // backspace button
                case 8:
                    if(inputContent.length === 0){
                        $("#directorySearchField .fa-times").css('opacity', 0);
                        searchForTextInTitles();
                    }
                    break;
                // delete button
                case 46:
                    $("#directorySearchField input").val("");
                    $("#directorySearchField .fa-times").css('opacity', 0);
                    searchForTextInTitles();
                    break;
                // enter button
                case 13:
                    searchForTextInTitles();
                    break;
                default:
                    break;
            }

            // update
            inputContent = $("#directorySearchField input").val();

            if(inputContent.length > 0){
                $("#directorySearchField .fa-times").css('opacity', 1);
            }
        });

        // click on a letter in the search bar
        $("#directoryLetters li").click(function () {
            const clickedLetter = $(this);

            // make all letters grey
            $("#directoryLetters li").css('color', '#666');

            // highlight the clicked letter
            clickedLetter.css('color', '#79A342');

            // empty the search field
            $("#directorySearchField input").val("");

            // search
            const searchLetter = $(this).text();
            searchByLetter(searchLetter);
        });


        ///////////////////////////
        // letter slider on mobile
        const mobileDirectoryLetters = $("#mobileDirectoryLetters");

        mobileDirectoryLetters.children().each( function( index ) {
            $(this).attr( 'data-position', index ); // NB: .attr() instead of .data()
        });

        console.log(333)
        if (mobileDirectoryLetters.length > 0) {
            console.log(666)
            // set up slider
            mobileDirectoryLetters.owlCarousel({
                items: 5,
                loop: true,
                nav: false,
                center: true,
                dots: false,
                autoplay: false,
                autoplayTimeout: 4200,
                smartSpeed: 900
            });

            // search by letter after slide translate
            mobileDirectoryLetters.on('translated.owl.carousel', function (event) {
                const centeredElement = $(".center").find("li").text();

                searchByLetter(centeredElement);
            });

            // search by clicked letter
            $(document).on('click', '.owl-item>li', function() {
                mobileDirectoryLetters.trigger('to.owl.carousel', $(this).data( 'position' ) );
            });

            // slider arrows
            $("#directorySearchBar .fa-chevron-left").click(function () {
                mobileDirectoryLetters.trigger('prev.owl.carousel');
            });
            $("#directorySearchBar .fa-chevron-right").click(function () {
                mobileDirectoryLetters.trigger('next.owl.carousel');
            });
        }
    }
});